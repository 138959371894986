<template>
	<!-- <div style=""> -->
		<div ref="hotTable"></div>
	<!-- </div> -->
</template>

<script>
	import Handsontable from 'handsontable';
	// import "handsontable/dist/handsontable.full.css";
	// import 'handsontable/languages/zh-CN';

	import {
		clearObjectVal
	} from '@/utils/common.js';
	
	import {
		calcHeight,
		settings
	} from '@/utils/hotTableSettings.js';
	
	import {
		columns_config,
		nestedHeadersTop_config
	} from './skuRelationHotTable.js';


	let columns = columns_config();
	
	let option = {
		height: calcHeight(490,260),
	}
	let nestedHeadersTop = [];
	let hiddenColumns = {
			columns: [2],
			// indicators: true,
			// exclude hidden columns from copying and pasting
			copyPasteEnabled: false
		};
	let settingData = settings(columns,option,nestedHeadersTop,hiddenColumns);
	let hotTableSettings = settingData.config;
	let DataRowObj = settingData.DataRowObj;
	
	export default {
		components: {

		},
		props: {
			// selectDicOption:{}
		},
		watch: {

		},
		data() {
			return {
				hotTable: {},
				// plugin: null,
				selectOption: {
					// wh_size_unit: [],
					// wh_packing_type:[],
					// wh_container_size:[]
				},
			};
		},
		created() {
			console.log('created');
			this.$nextTick(() => {
				this.initHotTable();
			});
		},
		mounted() {
			// this.$nextTick(() => {
			// 	this.getDicData();
			// });
		},
		methods: {
			//初始化HotTable
			initHotTable() {
				console.log('initHotTable');
				this.$nextTick(() => {
					console.log('initHotTable $nextTick');
					// let hotElement = document.querySelector('#hotTable');
					let hotElement = this.$refs.hotTable;
					// console.log('hotElement', hotElement);

					let hot = new Handsontable(hotElement, hotTableSettings);
					this.hotTable = hot;
					// this.plugin = this.hotTable.getPlugin('hiddenColumns');
					// console.log('this.hotTable', this.hotTable);
					this.clear();
				});
			},
			//更新列表配置
			updateSettingsColumns() {
				this.hotTable.updateSettings({
					columns: hotTableSettings.columns
				});
			},
			
			//初始化和加载数据
			loadData(objArr) {
				this.$nextTick(() => {
					// let data = this.formatterObjData(objArr);
					console.log('this.hotTable', this.hotTable);
					console.log('loadData', objArr);
					if(objArr.length<=0){
						this.clear();
					} else {
						this.hotTable.loadData(objArr);
					}
				});
				
			},
			//数组转对象
			formatterArrToObj(arr) {
				let reArr = [];
				arr.map((item,index) => {
					let obj = {}
					columns.map((item2,index2)=>{
						obj[item2.data] = item[index2];
					});
					reArr.push(obj);
					
				});
				return reArr;
			},
			//获取数据
			getData() {
				let hotTableData = this.hotTable.getData();
				// console.log(hotTableData);
				let data = this.formatterArrToObj(hotTableData);
				// console.log(data);
				return data;
			},
			//清空数据
			clear() {
				this.$nextTick(() => {
					// console.log('clear', DataRowObj);
					// this.loadData([DataRowObj])
					let new_DataRowObj = clearObjectVal(DataRowObj);
					this.hotTable.loadData([new_DataRowObj]);
				});

			},
			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_packing_type', 'wh_container_size',
			// 			'wh_size_unit'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 				this.selectOption.wh_container_size = data.data['wh_container_size'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.queryErrorFailed');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 			this.updateSettingsColumns();
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.queryRequestFailed'));
			// 			// this.initHotTable();
			// 		});
			// }
		}
	}
</script>

<style>
	/* .htSelectEditor {
		display: inline-block !important;
	} */
	.handsontable .htAutocompleteArrow {
		color:#999;
	}
</style>
