<template>
	<div class="mainTem">
		<el-form ref="form" inline :rules="formRules" :model="form" label-width="100px" style="width: 100%;"
			v-loading="loading">
			<el-card>
				<div slot="header">
				</div>
				<div>
					<el-form-item label="SKU" prop="goodsSku">
						<el-input type="text" v-model="form.goodsSku" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>

					<el-form-item :label="$t('i18nn_9168144190f66f5d')" prop="relaGoodsSku">
						<el-input :placeholder="$t('FormMsg.Please_Enter')" v-model="form.relaGoodsSku">
						</el-input>
					</el-form-item>

					<!-- <el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
							maxlength="" show-word-limit></el-input>
					</el-form-item> -->
				</div>
			</el-card>

		</el-form>
		<!-- <div>
			<div style="margin: 10px;">
				<el-button type="warning" @click="submitForm('form')" style="width: 200px;">
					{{$t('i18nn_514377f02a8481e6')}}</el-button>
			</div>
		</div> -->

	</div>
</template>
<script>
	export default {
		props: {
			row: {
				default: function() {
					return {};
				},
				type: Object
			},
		},

		components: {

		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改

				loading: false,
				loading_load: false,
				form: {
					id: null, //"",
					relaGoodsSku: '', //"",
					goodsSku: '', //"",

				},

				formRules: {
					goodsSku: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					relaGoodsSku: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
				},
				selectOption: {

				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
		},
		methods: {
			initData() {
				this.resetForm('form');
				if (this.$route.query && this.$route.query.id) {
					// this.form = this.row;
					this.getDetData(this.$route.query.id);
				} else {
					//新增
					// this.hasEdit = false;
					// this.resetForm('form');
				}
			},
			clear(){
				this.initData();
			},
			getData() {
				let formData = Object.assign({}, this.form);
				return [formData];
			},
			getDetData(id) {
				let filterData = {
					offset: 1,
					limit: 10,
					id: id,
				};

				this.loadin = true;
				this.$http
					.put(this.$urlConfig.WhSkuRelationPage, filterData)
					.then(({
						data
					}) => {
						this.loading = false;
						//表格显示数据
						if (200 == data.code && data.rows) {
							this.form = data.rows[0];
						}
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},
			//提交信息
			// submitForm(formName) {
			// 	this.$refs[formName].validate(valid => {
			// 		if (valid) {
			// 			let formData = Object.assign({}, this.form);

			// 			console.log(formData.id);

			// 			if (!formData.id) {
			// 				this.postData(this.$urlConfig.WhSkuRelationEdit, formData, () => {
			// 					console.log('submitSuccess');
			// 					this.$emit('submitSuccess');
			// 				});
			// 			} else {
			// 				// formData.userId = this.UserInfo.id;
			// 				this.postData(this.$urlConfig.WhSkuRelationAdd, formData, () => {
			// 					this.$emit('submitSuccess');
			// 				});
			// 			}
			// 		} else {
			// 			console.log('error submit!!');
			// 			this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			});
			// 			return false;
			// 		}
			// 	});
			// },
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				this.$nextTick(() => {
					if (this.$refs[formName]) {
						this.$refs[formName].resetFields();
						// }
					} else {
						console.log('this.$refs[formName]', this.$refs[formName]);
					}
				});
			},

			//提交信息
			// postData(url, formData, callback) {
			// 	// HttpTypelet _this = this;
			// 	this.loading = true;
			// 	// this.loading_load = true;
			// 	let HttpType = {};
			// 	// if ('delete' == type) {
			// 	// 	HttpType = this.$http.delete(url, formData);
			// 	// } else {
			// 	HttpType = this.$http.put(url, formData);
			// 	// }
			// 	HttpType.then(({
			// 		data
			// 	}) => {
			// 		console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 		console.log(data);
			// 		
			// 		this.loading = false;
			// 		// this.loading_load = false;
			// 		if (200 == data.code) {

			// 			this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			// 				type: 'success'
			// 			});
			// 			callback();
			// 			// }
			// 		} else {
			// 			// if (!data.msg) {
			// 			//   data.msg = this.$t('tips.submitError');
			// 			// }
			// 			this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			});
			// 		}
			// 	}).catch(error => {
			// 		console.log(error);
			// 		console.log(this.$t('tips.submitError'));
			// 		this.loading = false;
			// 		// this.loading_load = false;
			// 		this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 			//confirmButtonText: this.$t('204ffab8a6e01870'),
			// 		});
			// 	});
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);
			// 	this.loading_load = true;
			// 		this.$http
			// 			.put(this.$urlConfig.HyDicQueryList, ['wh_signature_service','biz_wh_is_shelf_label'])
			// 			.then(({ data }) => {
			// 				console.log('查询数据字典，请求成功');
			// 				console.log(data);
			// 				if (200 == data.code && data.data) {
			// 					// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 					// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 					// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 					this.selectOption.wh_signature_service = data.data['wh_signature_service'];
			// 					this.selectOption.biz_wh_is_shelf_label = data.data['biz_wh_is_shelf_label'];
			// 					// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 				} else {
			// 					if (!data.msg) {
			// 						data.msg = this.$t('tips.submitError');
			// 					}
			// 					this.$message.warning(data.msg);
			// 				}
			// 			})
			// 			.catch(error => {
			// 				console.log(error);
			// 				console.log('查询数据字典接口，请求失败');
			// 				this.$message.error(this.$t('tips.submitRequestError'));
			// 			});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
